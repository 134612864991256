<template>
  <section class="modal modal-slideup modal-slideup-open" v-bind:class="show">
    <h2
      class="modal--title"
      v-bind:aria-description="
        $root.localize('screens.Survey.ratingAriaDescription', {
          num: 5,
        })
      "
      v-on:click="hide"
    >
      How do you feel about this One-to-One Peer Support App?
    </h2>
    <form
      class="modal--content modal--content-rating"
      v-on:submit.prevent="submit"
    >
      <div class="fieldset fieldset-rating">
        <div class="field field-radio">
          <input
            type="radio"
            id="rating-1"
            name="rating"
            class="field--input field--input-radio"
          />
          <label
            for="rating-1"
            class="field--label field--label-rating"
            aria-describedby="rating-awful"
            >Very poor</label
          >
        </div>
        <div class="field field-radio">
          <input
            type="radio"
            id="rating-2"
            name="rating"
            class="field--input field--input-radio"
          />
          <label for="rating-2" class="field--label field--label-rating"
            >Poor</label
          >
        </div>
        <div class="field field-radio">
          <input
            type="radio"
            id="rating-3"
            name="rating"
            class="field--input field--input-radio"
          />
          <label for="rating-3" class="field--label field--label-rating"
            >Fair</label
          >
        </div>
        <div class="field field-radio">
          <input
            type="radio"
            id="rating-4"
            name="rating"
            class="field--input field--input-radio"
          />
          <label for="rating-4" class="field--label field--label-rating"
            >Good</label
          >
        </div>
        <div class="field field-radio">
          <input
            type="radio"
            id="rating-5"
            name="rating"
            class="field--input field--input-radio"
          />
          <label for="rating-5" class="field--label field--label-rating"
            >Very Good</label
          >
        </div>
      </div>
      <div class="field field-textarea">
        <label for="rating_comments" class="field--label"
          >Any comments on why you give it this rating?</label
        >
        <textarea
          class="field--input field--input-textarea"
          id="rating_comments"
          name="rating_comments"
          cols="30"
          rows="2"
        ></textarea>
      </div>
      <footer class="form--footer">
        <button type="submit" class="btn btn-primary btn-submit">
          {{ $root.localize("global.buttons.submit") }}
        </button>
      </footer>
    </form>
  </section>
</template>
<script>
export default {
  name: "SurveyPanel",

  data() {
    return {
      user: {},
      questions: [],
      answers: {},
      show: "showPanel",
    };
  },

  created() {
    this.$root.callAuthenticatedEndpointNEW(
      "getSurvey",
      (resp) => {
        this.questions = resp.questions;
      },
      "bf1b4d17-290d-4908-81f7-94e1b385ecb4" //TODO: make dynamic
    );
  },

  methods: {
    hide() {
      console.log("hide");
      this.show = "hidePanel";
    },
  },
};
</script>
<style scoped>
.hidePanel {
  display: none;
}
</style>
