<template>
    <template v-if="isMoreButton">
        <a 
            v-bind:class="buttonClass" 
            v-bind:disabled="disabledValue"
            v-on:click="handleClick"
        >
            <span class="btn--text">
                <slot></slot>
            </span>
        </a>
    </template>
    <template v-else>
        <a 
            v-bind:class="buttonClass"  
            v-bind:disabled="disabledValue"
            v-on:click="handleClick"
        >
        <slot></slot>
    </a>
    </template>
</template>
<script>
export default {
    name: "IthButton",

    inheritAttrs: false,

    emits: ['buttonClick'],

    props: {
        type: {
            type: String,
            required: false,
            default: "primary"
        },

        isDisabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            clicked: false, // Shows loading animation when true
        };
    },

    computed: {
        typeNormalized() {
            return this.type.toLowerCase();
        },

        isMoreButton() {
            return (this.typeNormalized === "more" || this.typeNormalized === "morecompact");
        },

        disabledValue() {
            if (!this.isDisabled && this.typeNormalized !== "disabled" && !this.clicked) {
                return null;
            }
            return "disabled";
        },

        buttonClass() {
            // All types of buttons use the base btn class
            let classString = "btn";

            // Additional classes create the various types of buttons
            switch (this.typeNormalized) {
                case "primary":
                    classString += " btn-primary";
                    break;

                case "none":
                    // bare btn class for "non-special" buttons
                    break;

                // Default to primary if invalid type passed
                default:
                    classString += " btn-primary";
            }

            // Disabled buttons need the btn-disabled calss to display correctly
            if (this.disabledValue === "disabled") {
                classString += " btn-disabled";
            }

            // Start loading animation when button clicked
            if (this.clicked) {
                classString += " btn-disabled btn-loading";
            }

            return classString;
        }
    },

    methods: {
        handleClick() {
            this.clicked = true;
            this.$emit('buttonClick');
        },

        // Will be called by parent after validation failure
        stopLoading() {
            this.clicked = false;
        }

    }
}
</script>