export default class InTandemApi {
  

  constructor() { 
    // generate the base URL for endpoint calls    
    if (process.env.VUE_APP_API_BASE_URL.startsWith("http")) {
      this.urlBase = process.env.VUE_APP_API_BASE_URL + "/";
      if (process.env.VUE_APP_API_PORT != 0) {
        this.urlBase = ":" + process.env.VUE_APP_API_PORT;
      }
    } else {
      let apiUrl = new URL(`${window.location.protocol}//${window.location.hostname}`);
      console.log("hostname: " + window.location.hostname);
      console.log("apiUrl: " + apiUrl);
      // port is used locally but not in the prod env
      if (process.env.VUE_APP_API_PORT != 0) {
        apiUrl.port = process.env.VUE_APP_API_PORT;
      }
      apiUrl.pathname = process.env.VUE_APP_API_BASE_URL;
      this.urlBase = apiUrl.toString();
    }
    console.log("urlBase: " + this.urlBase);
  }

  // Get the client/branch configuration for the current subdomain
  getConfig(callback, language) {
    console.log("Calling /meta/config?language=" + language);
    this.fetchBody(
      `meta/config?language=${language}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          callback(apiResponse);
        }
      },
      null,
      null
    );
  }

  // Get backend version information
  getVersion(callback) {
    console.log("Calling /meta/check");
    this.fetchBody(
      "meta/check",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          callback(apiResponse);
        }
      },
      null,
      null
    );
  }

  login(email, password, callback) {
    // Call the login endpoint and return an object to be consumed by UserPermissions
    console.log("Calling /login: " + email + ", " + password);
    this.fetchBody(
      "login",
      "POST",
      (apiResponse) => {
        console.log("API response from /login: " + JSON.stringify(apiResponse));
        // App function handles error condition
        callback(apiResponse);
      },
      null,
      { email: email, password: password }
    );
  }

  // Non-authenticated endpoint to resend the email invite
  resendEmailInvite(callback, email) {
    this.fetchBody(
      `users/public_resend_invitation?email=${email}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      null,
      null
    );
  }

  async resetSession(token) {
    console.log("Calling /meta/reset_session");
    const apiResponse = await this.fetchResponseOnly(
      "meta/reset_session",
      "GET",
      token
    );
    console.log("resetSession response: ");
    console.log(apiResponse);
    return apiResponse;
  }

  logout(token, callback) {
    let endpoint = `users/users/logout`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getSplashScreenMessage(token, callback) {
    this.fetchBody(
      `clients/initial_quote`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getAgreementJoin(token, callback, langCode) {
    this.fetchBody(
      `agreement_to_join?language_code=${langCode}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getAgreementVolunteer(token, callback, langCode) {
    this.fetchBody(
      `agreement_volunteer?language_code=${langCode}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getPrivacy(token, callback, langCode) {
    this.fetchBody(
      `privacy_terms?language_code=${langCode}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getTerms(token, callback, langCode) {
    this.fetchBody(
      `terms_of_use?language_code=${langCode}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }
  
  async consentToTerms(token) {
    console.log("Calling /users/users/sign_consent");
    const apiResponse = await this.fetchResponseOnly(
      "users/users/sign_consent",
      "POST",
      token
    );
    console.log("consentToTerms response: ");
    console.log(apiResponse);
    return apiResponse;
  }

  initialConsentToTerms(token, callback) {
    const init = this.prepareFetchHeaders("POST", token);
    fetch(this.urlBase + "users/users/sign_consent", init)
      .then((response) => {
        if (response.ok) {
          callback(true);
        }
        callback(false);
      })
      .catch(() => {
        callback(false);
      });
  }

  startApplication(callback, email, type, language) {
    this.fetchBody(
      "applicants/", /* trailing slash is required for this endpoint */
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      null,
      { "email": email, "type": type, "language_code": language }
    );
  }

  getApplicationForm(callback, type, token, language = "en-US") {
    let endpoint = `forms/applicant?type=${type}&applicant_id=${token}&language_code=${language}`;
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      null,
      null
    );
  }

  async changePassword(token, currentPassword, newPassword) {
    console.log("Calling /change_password");
    console.log("Change PW token: " + token);
    const apiResponse = await this.fetchResponseOnly(
      "change_password",
      "POST",
      token,
      {
        old_password: currentPassword,
        new_password: newPassword
      }
    );
    console.log("changePassword response: ");
    console.log(apiResponse);
    // if (apiResponse !== true) {
    //   return apiResponse.new_password[0];
    // }
    return apiResponse;
  }

  async changePasswordFirstLogin(token, newPassword) {
    console.log("Calling /first_change_password");
    console.log("Change PW token: " + token);
    const apiResponse = await this.fetchResponseOnly(
      "first_change_password",
      "POST",
      token,
      {
        new_password: newPassword
      }
    );
    // Retruns no body on success, body on failure
    console.log("changePasswordFirstLogin response: ");
    console.log(apiResponse);
    if (apiResponse === true) {
      return true;
    }
    return apiResponse.detail;
  }

  resetPassword(email, callback) {
    this.fetchBody(
      "password_reset/",
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      null,
      { email: email }
    );
  }

  setNewPassword(password, passwordResetToken, callback) {
    this.fetchBody(
      "password_reset/confirm",
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      null,
      { password: password, token: passwordResetToken}
    );
  }

  //Acknowledge viewing the "no medical advice" popup
  // Get the current user's details
  acknowledgeAdviceWarning(token, callback) {
    console.log("Calling /users/me:");
    this.fetchBody(
      "users/acknowledge_before_chat_message",
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }


  // Get the current user's details
  getSelf(token, callback, lang=null) {
    console.log("LANG: " + lang);
    let l = lang;
    if (l === null) {
      l = "en-US";
    }
    const endpoint = `users/me?language=${l}`
    console.log("Calling " + endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get the current user's mentor (if mentee)
  getMentor(token, callback) {
    console.log("Calling /users/mentors:");
    this.fetchBody(
      "users/mentors",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get the current user's mentees (if mentor)
  getMentees(token, callback) {
    console.log("Calling /users/mentees:");
    this.fetchBody(
      "users/mentees?count=1000",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors and no matches
          // Return just the list of mentees
          callback(apiResponse.results);
        }
      },
      token,
      null
    );
  }

  // Get the current user's coordinator
  getCoordinator(token, callback) {
    // Call the login endpoint and return an object to be consumed by UserPermissions
    console.log("Calling /users/coordinator");
    this.fetchBody(
      "users/coordinator",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          // Extract list of mentees from JSON
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getVideoStories(token, callback) {
    console.log("Calling /library/video_stories");
    this.fetchBody(
      "library/video_stories",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          console.log("error");
        } else {
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve a single user by (obfuscated) ID
  getUser(token, callback, userid) {
    console.log(`Calling /users/users/${userid}`);
    this.fetchBody(
      `users/users/${userid}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Retrieve users by user type; used in the admin interface
  getUsers(token, callback, userType) {
    console.log(`Calling /users/users?user_type=${userType}&count=1000`);
    this.fetchBody(
      `users/users?user_type=${userType}&count=1000`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Retrieve users of all types
  getAllUsers(token, callback) {
    console.log(`Calling /users/users?count=1000`);
    this.fetchBody(
      `users/users?count=1000`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // For the admin home page only
  getUsersAdminDashboard(token, callback, userType) {
    let endpoint;
    switch (userType) {
        case "mentor":
          endpoint = "mentors_dashboard_list";
          break;
        case "coordinator":
          endpoint = "admins_dashboard_list";
          break;
        default:
          endpoint = "mentees_dashboard_list";
      }
    console.log(`Calling /users/${endpoint}`);
    this.fetchBody(
      `users/${endpoint}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Chat conversation starter for mentors
  getConversationStarter(token, callback, userId) {
    const endpoint = `users/conversation_starter/${userId}`;
    console.log(`Calling /${endpoint}`);
    this.fetchBody(
      `${endpoint}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Chat conversation starter for mentees
  getConversationStarterMentee(token, callback) {
    const endpoint = `users/mentee_conversation_starter`;
    console.log(`Calling /${endpoint}`);
    this.fetchBody(
      `${endpoint}`,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // For the admin messaging page only
  getUsersAdminMessaging(token, callback) {
    const endpoint = "/users/mentors_mentees_messages_counts";
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

   // Retrieve users by user type and status; used for admin-side unmatched mentee count
  getUserCountByTypeAndState(token, callback, userType, state) {
    const url = `Calling /users/users?user_type=${userType}&state=${state}&count=1`;
    console.log(url);
    this.fetchBody(
      url,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Retrieve the current user's messages with one peer
  getMessages(userid, token, callback, fromDate = null) {
    let endpoint = `users/users/${userid}/messages`;
    if (fromDate !== null) {
      endpoint += `?from_date=${encodeURIComponent(fromDate)}`;
    }
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Send a message
  sendMessage(token, callback, userid, message) {
    console.log(`Calling /users/users/${userid}/messages`);
    this.fetchBody(
      `users/users/${userid}/messages`,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      { content: message }
    );
  }

  // Flag an array of messages as read
  flagMessagesAsRead(messageArray, token, callback) {
    console.log(`Calling /users/users/read_messages`);
    this.fetchBody(
      `users/users/read_messages`,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      { messages: messageArray }
    );
  }

  // Flag an array of messages as NOT read
  flagMessagesAsUnread(messageArray, token, callback) {
    console.log(`Calling /users/users/unread_messages`);
    this.fetchBody(
      `users/users/unread_messages`,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      { messages: messageArray }
    );
  }

  // Get messages from all coordinators to a peer or mentor
  getMessagesCoordinator(token, callback, fromDate = null) {
    let endpoint = `users/coordinators_chat`;
    if (fromDate !== null) {
      endpoint += `?from_date=${encodeURIComponent(fromDate)}`;
    }
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token
    );
  }
  
  // Send message to all coordinators for disease condition
  sendMessageCoordinator(token, callback, message) {
    console.log(`users/coordinators_chat`);
    this.fetchBody(
      `users/coordinators_chat`,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      { content: message }
    );
  }

  // For use by coordinators: get all coordinator messages to/from  given user, with optional fromDate filter
  getMessagesCoordinatorUser(userId, token, callback, fromDate = null) {
    let endpoint = `users/coordinators_messages/${userId}`;
    if (fromDate !== null) {
      endpoint += `?from_date=${encodeURIComponent(fromDate)}`;
    }
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token
    );
  }

  // Send from a coordinator to a peer or mentor
  sendMessageFromCoordinator(token, callback, userid, message) {
    console.log(`users/coordinators_messages/${userid}`);
    this.fetchBody(
      `users/coordinators_messages/${userid}`,
      "PUT",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      { content: message }
    );
  }

  // Get the topic list for session notes
  getSessionNoteTopics(token, callback) {
    let endpoint = `users/session_notes_topics`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get the topic list for session notes
  getSessionNoteTopicsByCondition(token, callback, id) {
    let endpoint = `users/session_notes_topics/${id}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve a mentor's session notes for a single peer
  getSessionNotes(userid, token, callback) {
    let endpoint = `users/users/${userid}/session_notes`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Add a single session note (by a mentor)
  addSessionNote(
    userid,
    token,
    callback,
    notes,
    meetingDate,
    duration,
    atRisk,
    leavingProvider,
    topics,
    meetingType
  ) {
    let endpoint = `users/users/${userid}/session_notes`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      {
        notes: notes,
        meeting_date: meetingDate,
        duration: duration,
        patient_at_risk: atRisk,
        risk_leaving_provider: leavingProvider,
        topics: topics,
        meeting_type: meetingType
      }
    );
  }

  // Retrieve status options for the current Intandem client
  getStatuses(token, callback) {
    console.log("Calling /clients/users/statuses");
    this.fetchBody(
      "clients/users/statuses",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve language options for the current Intandem client (admin only)
  getLanguages(token, callback) {
    console.log("Calling users/languages");
    this.fetchBody(
      "users/languages",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  //Peer/mentor version of getLanguages; also returns translated lang. name
  getPeerMentorLanguages(token, callback) {
    console.log("Calling users/languages");
    this.fetchBody(
      "users/languages",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve gender options for the current Intandem client
  getGenders(token, callback) {
    console.log("Calling /clients/users/genders");
    this.fetchBody(
      "clients/users/genders",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve gender options for the current Intandem client
  getEthnicities(token, callback) {
    console.log("Calling /clients/users/ethnicities");
    this.fetchBody(
      "clients/users/ethnicities",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve gender options for the current Intandem client
  getConditions(token, callback) {
    console.log("Calling /clients/conditions");
    this.fetchBody(
      "clients/conditions",
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Add a user
  addUser(
    token,
    callback,
    userObject
  ) {
    let endpoint = `users/users`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          if (apiResponse.error) {
            let output = { error: true, messages: [] };
            for (let key in apiResponse) {
              if (key !== "error") {
                output.messages.push(...apiResponse[key]);
              }
            }
            callback(output);
          } else {
            apiResponse.error = false;
            callback(apiResponse);
          }
        }
      },
      token,
      userObject
    );
  }

  // Update a single user by (obfuscated) ID
  /*  User object (all fields required, except email):
    {
      "first_name": "string",
      "last_name": "string",
      "middle_initial": "string",
      "birth_date": "2022-02-13",
      "email": "user@example.com", 
      "phone": "string",
      "disease_condition": "string",
      "avatar": "string",
      "city": "string",
      "gender": "string",
      "language": "string",
      "ethnicity": "string",
      "receives_sms_notifications": true,
      "notification_type": "email",
      "extra_fields": "object" [optional]
    }
  */
  updateUser(token, callback, userid, userObject) {
    console.log(`Calling PATCH /users/users/${userid}`);
    // email updates not allowed, so remove that key from the user object if it exists
    delete userObject.email;      

    this.fetchBody(
      `users/users/${userid}`,
      "PATCH",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      userObject
    );
  }

  // Resend invitation email to a new user
  async resendInvitation(token, userId) {
    const endpoint = `users/resend_invitation?receiver=${userId}`;
    console.log(endpoint);
    const apiResponse = await this.fetchResponseOnly(endpoint, "GET", token);
    console.log(`${endpoint} response: `);
    console.log(apiResponse);
    return(apiResponse);
  }

  // Retrieve unmatched mentees; used in the admin interface
  getUsersToMatch(token, callback) {
    // /users/unmatched_mentees
    let endpoint = `users/unmatched_mentees`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response, getUsersToMatch() users/unmatched_mentees: " + JSON.stringify(apiResponse, null, 2));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Retrieve the potential mentor matches for one mentee
  getMatchingUsers(token, callback, id) {
    let endpoint = `users/users/${id}/match`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response, getMatchingUsers() users/users/${id}/match: " + JSON.stringify(apiResponse, null, 2));
        callback(apiResponse);
      },
      token,
      null
    );
  }



  // Get matching mentors except the currently matched mentor. Used when rematching a peer.
  getMatchingUsersForRematch(token, callback, id) {
    let endpoint = `users/users/${id}/new_matches`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Match a mentee and mentor
  async matchUsers(token, callback, mentee, mentor) {
    let endpoint = "users/mentors/approve";
    console.log(endpoint);
    const apiResponse = await this.fetchResponseOnly(endpoint, "POST", token, {
      mentor: mentor,
      mentee: mentee,
    });
    console.log(`${endpoint} response: `);
    console.log(apiResponse);
    callback(apiResponse);
  }

  // Rematch a mentee and mentor
  async rematchUsers(token, callback, mentee, mentor) {
    let endpoint = "users/mentors/rematch";
    console.log(endpoint);
    const apiResponse = await this.fetchResponseOnly(endpoint, "PATCH", token, {
      mentor: mentor,
      mentee: mentee,
    });
    console.log(`${endpoint} response: `);
    console.log(apiResponse);
    callback(apiResponse);
  }

  // Remove a match between mentee and mentor
  async unmatchUsers(token, callback, mentee) {
    let endpoint = "users/mentors/unmatch";
    console.log(endpoint);
    const apiResponse = await this.fetchResponseOnly(endpoint, "PATCH", token, {
      mentee: mentee
    });
    console.log(`${endpoint} response: `);
    console.log(apiResponse);
    callback(apiResponse);
  }

  // Inactivate a user
  inactivateUser(id, token, callback) {
    let endpoint = `users/users/${id}/status`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      { status: "Inactive" }
    );
  }

  // Activate a user. Sets the user to status Enrolled.
  activateUser(id, token, callback) {
    let endpoint = `users/users/${id}/status`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      { status: "Enrolled" }
    );
  }

  // Retrieve urgent matter counters in the admin interface
  getAdminCounters(token, callback) {
    let endpoint = `users/admin_counters`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve urgent alerts (union of emergency + risk of leaving); used in the admin interface
  getUrgentNotes(token, callback) {
    let endpoint = `users/session_notes?urgent=true&status=open`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get both open and closed urgent notes
  getAllUrgentNotes(token, callback) {
    let endpoint = `users/session_notes?urgent=true&count=500`;
    console.log(endpoint);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve a single mentees's session notes (all statuses)
  getUrgentSessionNotesByUser(menteeId, token, callback) {
    let endpoint = `users/users/${menteeId}/session_notes`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve urgent session note actions
  getUrgentSessionNoteActions(token, callback) {
    let endpoint = "clients/session_note_actions";
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Update (and optionally close) an urgent session note with action and action notes
  updateUrgentSessionNote(
    noteId,
    token,
    callback,
    action,
    actionNote,
    close = false
  ) {
    let endpoint = `users/users/session_notes/${noteId}/action`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      {
        action: action === null ? "" : action,
        action_notes: actionNote === null ? "" : actionNote,
        close: close,
      }
    );
  }

  getVideoStoryById(token, callback, id) {
    let endpoint = `library/video_stories/${id}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve a resource category by ID
  getResourceCategoryById(token, callback, id) {
    let endpoint = `library/categories/${id}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve all resources for a given category
  getResourcesByCategory(token, callback, categoryId) {
    let endpoint = `library/articles?category=${categoryId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Retrieve a single resource by ID
  getResourceById(token, callback, resourceId) {
    let endpoint = `library/articles/${resourceId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Share a resource with one or more people
  // TODO - check for bad article/recipient ID response from backend?
  async shareResource(token, callback, resourceId, recipientIds, note) {
    let endpoint = "library/articles/share";
    console.log(`Calling ${endpoint}`);
    const apiResponse = await this.fetchResponseOnly(endpoint, "POST", token, {
      article: resourceId,
      receivers: recipientIds,
      note: note,
    });
    callback(apiResponse);
  }

  // track external link click
  async trackExternalLink(token, resourceUrl) {
    const endpoint = `click_track?type=external_link&resource=${resourceUrl}`;
    console.log(`Calling ${endpoint}`);
    await this.fetchResponseOnly(endpoint, "GET", token);
    console.log(`Tracked external link: ${resourceUrl}`);
  }

  // Array of tips for post-login splash screen
  getMentorTipsLogin(token, callback) {
    let endpoint = `library/mentor_tips_list`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }
  getMenteeTipsLogin(token, callback) {
    let endpoint = `library/mentee_tips_list`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getMentorTips(token, callback) {
    let endpoint = `library/mentor_tips`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getDoctorQuestions(token, callback) {
    let endpoint = `library/doctor_questions`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get details of the current authenticated user
  getCurrentUser(token, callback) {
    let endpoint = `users/me`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getMentorQuestions(token, callback) {
    let endpoint = `users/meeting_prepare`;
    console.log(`Calling GET ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getPeerDoctorQuestions(token, callback) {
    let endpoint = `users/doctor_meeting_prepare`;
    console.log(`Calling GET ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  updateMentorQuestions(token, callback, data) {
    console.log(data);
    let endpoint = `users/meeting_prepare`;
    console.log(`Calling POST ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      data
    );
  }

  updatePeerDoctorQuestions(token, callback, data) {
    console.log(data);
    let endpoint = `users/doctor_meeting_prepare`;
    console.log(`Calling POST ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      data
    );
  }

  // Get biography of the current authenticated mentee
  getCurrentUserBiography(token, callback) {
    let endpoint = `users/biography`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getMenteeConcerns(token, callback) {
    let endpoint = `users/concerns_list`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get biography of the current authenticated mentor
  getCurrentMentorBiography(token, callback) {
    let endpoint = `users/mentor_biography`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get biography of a mentee
  getUserBiography(token, callback, id) {
    let endpoint = `users/biography/${id}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Get biography of a mentor
  getMentorBiography(token, callback, id) {
    let endpoint = `users/mentor_biography/${id}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  // Update biography of the current authenticated mentee
  updateCurrentUserBiography(token, callback, bio) {
    let endpoint = `users/biography`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      bio
    );
  }

  // Update biography of the current authenticated mentor
  updateCurrentMentorBiography(token, callback, bio) {
    let endpoint = `users/mentor_biography`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      bio
    );
  }

  getSurvey(token, callback, surveyId) {
    let endpoint = `users/surveys/${surveyId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getStaticSurvey(token, callback, surveyId) {
    let endpoint = `users/static_survey/${surveyId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      null
    );
  }

  getLogoutSurvey(surveyToken, callback) {
    let endpoint = `users/logout_surveys/?survey_key=${surveyToken}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      null,
      null
    );
  }

  answerSurvey(token, callback, answers, surveyId) {
    for (let answer in answers) {
      this._answerSurveyQuestion(
        token,
        (response) =>
          console.log(
            "_answerSurveyQuestion resp: " + JSON.stringify(response)
          ),
        answers[answer],
        surveyId
      );
    }
    callback(true);
  }

  // TODO - add survey_id, then test message changes
  _answerSurveyQuestion(token, callback, answer, surveyId) {
    let endpoint = `surveys/answer?survey_id=${surveyId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      answer
    );
  }

  answerStaticSurvey(token, callback, answers, surveyId) {
    for (let answer in answers) {
      this._answerStaticSurveyQuestion(
        token,
        (response) =>
          console.log(
            "_answerStaticSurveyQuestion resp: " + JSON.stringify(response)
          ),
        answers[answer],
        surveyId
      );
    }
    callback(true);
  }

  // TODO - add survey_id, then test message changes
  _answerStaticSurveyQuestion(token, callback, answer, surveyId) {
    let endpoint = `users/static_survey/${surveyId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      answer
    );
  }

  answerLogoutSurvey(surveyToken, callback, answers) {
    for (let answer in answers) {
      this._answerLogoutSurveyQuestion(
        surveyToken,
        (response) =>
          console.log(
            "_answerLogoutSurveyQuestion resp: " + JSON.stringify(response)
          ),
        answers[answer]
      );
    }
    callback(true);
  }

  _answerLogoutSurveyQuestion(surveyToken, callback, answer) {
    let endpoint = `surveys/answer?survey_key=${surveyToken}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      null,
      answer
    );
  }

   getPersonalNotes(token, callback, userId) {
    let endpoint = `users/personal_notes?receiver_id=${userId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token
    );
  }

  createPersonalNote(token, callback, userId, notes) {
    let endpoint = `users/personal_notes`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      {"receiver": userId, "notes": notes}
    );
  }

  editPersonalNote(token, callback, userId, notes) {
    let endpoint = `users/personal_notes/${userId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "PATCH",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      {"notes": notes}
    );
  }

  createAdminNote(token, callback, userId, note) {
    let endpoint = `users/admin_notes`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "POST",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token,
      {"notes": note, "receiver": userId}
    );
  }

  getAdminNotes(token, callback, userId) {
    let endpoint = `users/admin_notes?receiver_id=${userId}`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token
    );
  }

  getAvatar(token, callback) {
    let endpoint = `users/avatar`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token
    );
  }

  deleteAvatar(token, callback) {
    let endpoint = `users/avatar`;
    console.log(`Calling ${endpoint}`);
    this.fetchBody(
      endpoint,
      "DELETE",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        if (apiResponse === "Error") {
          //callback(false);
          console.log("error");
        } else {
          // TODO - detect errors
          callback(apiResponse);
        }
      },
      token
    );
  }

  // Transport layer - call an API endpoint and return object or Boolean as a response

  // Prepare headers for API fetch calls
  prepareFetchHeaders(method, token = null, params = null) {
    let init = {
      method: method.toUpperCase(),
    };
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    if (token) {
      headers.append("Authorization", `bearer ${token}`);
    }
    init.headers = headers;
    if (params) {
      init.body = JSON.stringify(params);
    }
    return init;
  }

  // Call endpoints that don't return a body in the case of success
  async fetchResponseOnly(endpoint, method, token = null, params = null) {
    const init = this.prepareFetchHeaders(method, token, params);
    try {
      const response = await fetch(this.urlBase + endpoint, init);
      // Response only messages will return a body with an error message in the case of failure
      if (response.ok) {
        return true;
      } else {
        const output = await response.json();
        output["error"] = true;
        return output;
      }
    } catch (error) {
      // fetch() will only throw an error if the API is unreachable
      return error.message;
    }
  }

  // Call endpoints that always return a response body.
  // TODO - error handling may not work for login, will need a special case
  fetchBody(endpoint, method, callback, token = null, params = null) {
    let init = this.prepareFetchHeaders(method, token, params);
    let error = false;
    fetch(this.urlBase + endpoint, init)
      .then((response) => {
        // in case of an error, append error flag to returned object
        if (!response.ok) {
          error = true;
        }
        return response.json();
      })
      .then((jsonResponse) => {
        console.log("Called backend API: " + endpoint);
        let output = jsonResponse;
        output["error"] = error;
        console.log(output);
        callback(output);
      })
      .catch((error) => {
        // An error would be caused by a 500 or 404 response, both of which indicate an invalid session on the backend
        // (or that the backend is down)
        console.log("fetch error: " + error);
        //send error back to app to force logout
        callback({ message: 'Invalid session or endpoint unavailable', error: true, invalidToken: true, });
      });
  }

  // Avatar upload - requires a unique fetch call
  uploadAvatar(contentType, fileName, contentLength, binaryData, callback, token) {
    // Prepare request
    let init = {
      method: "POST",
    };
    let headers = new Headers();
    headers.append("Content-Type", contentType);
    headers.append("Content-Length", contentLength);
    headers.append("Content-Disposition", `attachement; filename=${fileName}`);
    headers.append("Authorization", `bearer ${token}`);
    init.headers = headers;
    init.body = binaryData;
    //console.log(init);

    // make request
    let error = false;
    fetch(this.urlBase + "users/avatar", init)
      .then((response) => {
        // in case of an error, append error flag to returned object
        if (!response.ok) {
          error = true;
        }
        return response.json();
      })
      .then((jsonResponse) => {
        console.log("Called backend API: users/avatar");
        let output = jsonResponse;
        output["error"] = error;
        console.log(output);
        callback(output);
      })
      .catch((error) => {
        // An error would be caused by a 500 or 404 response, both of which indicate an invalid session on the backend
        // (or that the backend is down)
        console.log("fetch error: " + error);
        //send error back to app to force logout
        callback({ message: 'Invalid session or endpoint unavailable', error: true, invalidToken: true, });
      });
  }

  // Retrieve unmatched mentees
  getUnmatchedMentees(token, callback) {
    let endpoint = `users/unmatched_mentees`;
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

  // Get matching users for a specific user
  getMatchingUsersForUser(token, callback, id) {
    let endpoint = `users/users/${id}/match`;
    this.fetchBody(
      endpoint,
      "GET",
      (apiResponse) => {
        console.log("API response: " + JSON.stringify(apiResponse));
        callback(apiResponse);
      },
      token,
      null
    );
  }

}
