/*eslint-disable*/
let appConfig = {
  // User type strings
  COORD_USER_TYPE: "coordinator",
  MENTOR_USER_TYPE: "mentor",
  MENTEE_USER_TYPE: "mentee",

  // Field rules
  PASSWORD_MIN_LENGTH: 12,
  PASSWORD_MAX_LENGTH: 60,

  PASSWORD_EXPIRATION_MAX_DAYS: 90,

  MESSAGING_POLL_INTERVAL: 3000, // Number of milliseconds between polling for new messages in the chat screen

  ADMIN_POLL_INTERVAL: 20000, // Number of milliseconds between polling for new admin notifications

  ADMIN_HOME_SEARCH_MIN_LENGTH: 2, // Minimum number of character to trigger dynamic search on the admin home table

  USER_MIN_AGE: 18, // Minimum age for a user (of any type)

  // Parameters for date/time formatting functions
  DATETIMEFORMAT_DATE: "medium",
  DATETIMEFORMAT_WEEKDAY: "long",

  SESSION_TIMEOUT: 1800000, // 30 minutes
  SESSION_TIMEOUT_WARNING: 117000, // 2 minutes - 3 seconds

  // error messages returned by the login endpoint
  ACCOUNT_LOCKED_MESSAGE: "Your account has been suspended",
  PASSWORD_EXPIRED_MESSAGE: "Your password has expired. Please reset your password.",
};
export default appConfig;
