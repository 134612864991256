<template>
    <img
    class="main--hero"
    v-bind:src="$root.config.hero_image"
    width="800"
    height="398"
    v-bind:alt="$root.config.hero_alt"
  />
    <div class="language" v-if="supportedLanguages.length > 1">
        <div class="language--title">
        {{ $root.localize("fieldLabels.languagePref") }}
        </div>
        <ul class="language--list">
            <li
                v-for="locale in supportedLanguages"
                v-bind:key="locale"
                class="language--item"
            >
                <a
                v-if="$i18n.locale !== locale"
                v-on:click="$root.setLocale(locale)"
                href="#"
                >{{ $i18n.messages[locale].languageName }}</a
                >
                <span v-else>{{ $i18n.messages[locale].languageName }}</span>
            </li>
        </ul>
    </div>
    <div class="start--section">
        <div class="start--question">Do you want to speak to another Cedars-Sinai patient who has been in your shoes?</div>
        <div class="start--answer">Yes! <router-link v-bind:to="{ name: 'ApplicationStart', params: { type: $root.config.MENTEE_USER_TYPE } }" class="btn btn-primary">I’d like to be mentored</router-link></div>
    </div>
    <div class="start--section">
        <div class="start--question">Would you like to share your experiences with another Cedars-Sinai patient and pay it forward?</div>
        <div class="start--answer">Yes! I’d like to <router-link v-bind:to="{ name: 'ApplicationStart', params: { type: $root.config.MENTOR_USER_TYPE } }" class="btn btn-primary">become a mentor</router-link></div>
 
    </div>
    <p>Already have an account? <router-link v-bind:to="{ name: 'Login' }">Login here</router-link>.</p>
</template>
<script>
import BodyClass from "../mixins/BodyClass";
import PageTitle from "../mixins/PageTitle";
export default {
    name: "Start",

    mixins: [BodyClass, PageTitle],

    data() {
        return {
            bodyClass: "start",
            supportedLanguages: ['en-US'],
        }
    },

    created() {
        this.supportedLanguages = this.$root.getLocales();
    }
}
</script>