<template>
  <BaseModalDialog>
    <h2>Message Alert</h2>
    <p>
      You have <strong class="modal--num">{{ urgentMessageCount }}</strong>
      Session Notes Alert<template v-if="urgentMessageCount > 1">s</template>
    </p>
    <router-link
      v-on:click="$root.setUrgentMessageAcknowledged()"
      v-bind:to="{ name: 'AdminMessaging' }" 
      class="btn btn-primary"
      >View Messages Alerts</router-link
    >
  </BaseModalDialog>
</template>

<script>
import BaseModalDialog from "./BaseModalDialog.vue";
export default {
  name: "ModalDialogUrgentMessage",

  components: { BaseModalDialog },

  computed: {
    urgentMessageCount() {
      return this.$root.urgentNoteCount;
    },
  },
};
</script>
