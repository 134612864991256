import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueDOMPurifyHTML from "vue-dompurify-html";
import messages from "@/locales/messages.js";
import { createI18n } from "vue-i18n";

// Check for language overrides
let locale = "en-US";
const isoRegex = /^[a-z]{2}(-[A-Z]{2}){0,1}$/; // language params must be ISO 639-1 codes

// Grab from user's browser language preferences.
// navigator.languages is an array of languages in preference order; we want the
// first one that matches a known language for this app
// TODO - decide if this still makes sense
// for (const langPref in navigator.languages) {
//   if (langPref in messages && isoRegex.test(langPref)) {
//     locale = langPref;
//     break;
//   }
// }

const storedLang = localStorage.getItem("lang");
if (
  storedLang !== null &&
  isoRegex.test(storedLang) &&
  storedLang in messages
) {
  locale = storedLang;
}

// Get display language from URL params
const params = new URLSearchParams(window.location.search);
const lang = params.get("language_code");
if (lang !== null && isoRegex.test(lang) && lang in messages) {
  locale = lang;
}

// persist language setting
localStorage.setItem("lang", locale);

const i18n = createI18n({
  locale: locale,
  fallbackLocale: "en-US",
  messages: messages,
});

const app = createApp(App)
  .use(router)
  .use(VueDOMPurifyHTML)
  .use(i18n)
  .mount("#app");
router.app = app; // Required in Vue 3 to let the router see the application instance
