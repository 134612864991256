export default {
  //Lets page view components set the appropriate class on the <main> element.
  // The view must define the appropriate mainClass in data()
  beforeMount() {
    if (this.bodyClass) {
      document.querySelector("main").className = this.mainClass;
    } else {
      document.querySelector("main").className = "";
    }
  },
};
