<template>
  <section v-if="submitted" class="modal">
    <div class="modal--inner">
      <h2>
        {{ $root.localize("screens.Survey.modalConfirmationHeader") }}
      </h2>
      <p>{{ $root.localize("screens.Survey.modalConfirmationText") }}</p>
      <router-link v-bind:to="{ name: 'Login' }" class="btn btn-primary">{{
        $root.localize("global.buttons.proceed")
      }}</router-link>
    </div>
  </section>
  <router-link v-bind:to="{ name: 'Login' }" class="btn btn-primary">{{
    $root.localize("global.buttons.goToLogin")
  }}</router-link>
  <section class="survey" v-if="this.survey !== 'none' && Array.isArray(this.multipleChoiceQuestions)">
    <h2
      class="survey--title"
      v-on:click="hide()"
      v-bind:aria-description="
        $root.localize('screens.Survey.ratingAriaDescription', {
          num: multipleChoiceQuestions[0].question_options.length,
        })
      "
    >
      {{ multipleChoiceQuestions[0].title }}
    </h2>
    <form class="modal--content modal--content-rating" v-on:submit.prevent="submit">
      <div class="fieldset rating">
        <div
          class="field field-radio"
          v-for="(option, index) in multipleChoiceQuestions[0].question_options"
          v-bind:key="option.id"
        >
          <input
            type="radio"
            v-bind:id="option.id"
            v-bind:name="multipleChoiceQuestions[0].id"
            class="field--input field--input-radio"
            v-bind:value="option.id"
            v-model="answers[multipleChoiceQuestions[0].id]"
            v-on:click="multipleChoiceClick(multipleChoiceQuestions[0].id)"
          />
          <label
            v-bind:for="option.id"
            class="field--label rating--label"
            aria-describedby="rating-awful"
            >{{ option.value }}</label
          >
          <span v-if="index === 0" class="rating--description rating--description-first">{{ multipleChoiceQuestions[0].lower_option_title }}</span>
          <span 
            v-if="index === multipleChoiceQuestions[0].question_options.length - 1" 
            class="rating--description rating--description-first">
            {{ multipleChoiceQuestions[0].higher_option_title }}
          </span>
        </div>
      </div>
      <div
        class="field field-textarea"
        v-for="question in notesQuestions"
        v-bind:key="question.id"
      >
        <label class="field--label" v-bind:for="question.id">{{
          question.title
        }}</label>
        <textarea
          class="field--input field--input-textarea"
          v-bind:id="question.id"
          v-model="answers[question.id]"
          v-bind:name="question.id"
          cols="30"
          rows="2"
        ></textarea>
      </div>
      <footer class="form--footer">
        <IthButton
          v-on:buttonClick="handleSubmit()"
          v-bind:disabled="!allMultipleChoiceQuestionsAnswered"
          class="btn btn-primary btn-submit"
          ref="submitButton"
        >
          {{ $root.localize("global.buttons.submit") }}
        </IthButton>
      </footer>
    </form>
  </section>
</template>

<script>
import BodyClass from "../mixins/BodyClass";
import MainClass from "../mixins/MainClass";
import PageTitle from "../mixins/PageTitle";
import IthButton from "../components/IthButton.vue";
export default {
  name: "Logout",

  components: {IthButton},

  mixins: [BodyClass, MainClass, PageTitle],

  // props: {

  // },

  data() {
    /* Defaults to password hidden state */
    return {
      bodyClass: "notification",
      mainClass: "notification--body",
      questions: {},
      answers: {},
      multipleChoiceQuestionsAnswered: new Set(),
      hidden: true,
      survey: "none",
      submitted: false,
    };
  },

  computed: {
    multipleChoiceQuestions() {
      if (this.survey !== "none" && Array.isArray(this.questions)) {
        return this.questions.filter(
          (question) => question.question_type === "multiple_choice"
        );
      }
      return null;
    },
    notesQuestions() {
      if (this.survey !== "none") {
        return this.questions.filter(
          (question) => question.question_type === "notes"
        );
      }
      return null;
    },

    // check that all multiple choice questions have answers
    allMultipleChoiceQuestionsAnswered() {
      return (
        this.multipleChoiceQuestionsAnswered.size ===
        this.multipleChoiceQuestions.length
      );
    },
  },

  created() {
    this.survey = this.$route.params.survey;
    if (this.survey !== "none") {
      this.$root.api.getLogoutSurvey(this.survey, (resp) => {
        this.questions = resp.questions;
        this.hidden = false;
      });
    }
  },

  methods: {
    show() {
      this.hidden = false;
    },
    hide() {
      this.hidden = true;
    },

    multipleChoiceClick(questionId) {
      this.multipleChoiceQuestionsAnswered.add(questionId);
    },

    handleSubmit() {
      if (!this.allMultipleChoiceQuestionsAnswered) {
        this.$refs.submitButton.stopLoading();
        return;
      }

      // Build an array of answers with question IDs, that can be consumed by /surveys/answer
      let answerArray = [];
      for (const answer in this.answers) {
        const q = this.questions.find((question) => question.id === answer);
        const type = q["question_type"];
        let option =
          type === "multiple_choice"
            ? this.answers[answer] // For multiple choice questions, the answer is the option ID
            : q["question_options"][0].id; // For notes questions, get the option ID from the question
        let value = type === "notes" ? this.answers[answer] : "";
        answerArray.push({
          question: answer,
          question_option: option,
          value: value,
        });
      }
      this.$root.api.answerLogoutSurvey(
        this.survey,
        (resp) => {
          console.log(resp);
          this.submitted = true;
          this.hidden = true;
          this.$refs.submitButton.stopLoading();
        },
        answerArray
      );
    },
  },
};
</script>
