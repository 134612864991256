<template>
  <router-view name="Header" />
  <main>
    <section v-if="showSessionTimeoutWarning" class="modal">
      <div class="modal--inner">
        <h2>{{ localize("global.sessionTimeoutWarning.header") }}</h2>
        <p>
          {{
            localize("global.sessionTimeoutWarning.message", {
              time: logoutCountdownDisplay,
            })
          }}
        </p>
        <p>{{ localize("global.sessionTimeoutWarning.stillHere") }}</p>
        <a
          href="#"
          v-on:click="handleContinueSessionClick()"
          class="btn btn-primary"
          >{{ localize("global.buttons.stillHere") }}</a
        >
        <a href="#" v-on:click="logout()">{{
          localize("global.buttons.logOut")
        }}</a>
      </div>
    </section>
    <router-view name="Modal" />
    <router-view />
  </main>
  <footer>
    ©{{ now.getFullYear() }} InTandem Health
    <template v-if="feVersion != '' || beVersion != ''">
      <br><span>Version: 
      <template v-if="feVersion != ''">{{ feVersion }}</template>
      <template v-else>N/A</template>
    -<template v-if="beVersion != ''">{{ beVersion }}</template>
    <template v-else>N/A</template>
    </span>
    </template>
  </footer>
  <component v-bind:is="modalComponent"></component>
</template>

<script>
// @ is an alias to /src
import UserPermissions from "@/classes/UserPermissions";
import InTandemApi from "@/classes/InTandemApi";
import appConfig from "@/config/appConfig.js";
import ModalDialogUrgentMessage from "@/components/ModalDialogUrgentMessage";
import SurveyPanel from "@/components/SurveyPanel";

export default {
  name: "App",

  components: {
    ModalDialogUrgentMessage,
    SurveyPanel,
  },

  data() {
    return {
      // $data.user contains a UserPermissions instance,
      // which manages the current user's authentication and permissions.
      // It's tempting to make user a property, but the logout component
      // Needs to mutate user, and that would violate the one-way data flow
      // rule for properties.
      user: {},

      // An IntandemApi instance. All InTandem API calls app-wide go through here.
      api: new InTandemApi(),

      // app-wide config variables, loaded from config/appConfig.js
      config: {},

      // For the footer copyright year display
      now: new Date(),

      // Cache for lookup table values
      lookupValues: new Map(),

      // If > 1, display urgent message modal on the admin interface (after login)
      //urgentMessageCount: 0,

      // Toggles display of the session timeout modal
      showSessionTimeoutWarning: false,

      // We'll place the return value from the setTimeout for the session timer
      sessionTimeoutRef: null,

      // Holds the reference to the countdown setInterval ref for the timeout warning modal
      sessionTimeoutCountdownRef: null,

      // Logout countdown displayed prior to session timeout. Value will be overwritten by app config
      logoutCountdown: 60,

      // Session worker reference
      sessionWorker: null,

      // Admin top nav counts
      unmatchedMentees: 0,
      urgentNoteCount: 0,
      unreadMessageCount: 0,

      // flags whether to show medical advice popup to peer/mentor user
      showPeerMentorAdviceModal: false,

      // Structure for client & condition-specific user fields
      conditionUserFields: {},

      // suppress urgent alert
      urgentAlertAcknowledged: false,

      // for medical advice warning popup
      loginData: {},

      chatIntervalId: null,
      adminIntervalId: null,

      // For version display at the bottom of the page
      feVersion: '',
      beVersion: '',
    };
  },

  computed: {
    modalComponent() {
      // If there are open urgent alerts, show the urgent alert dialog to admins (except on the interstital view)
      if (
        this.userIsCoordinator() &&
        this.urgentNoteCount > 0 &&
        this.$route.meta.showUrgentAlert &&
        !this.urgentAlertAcknowledged &&
        sessionStorage.getItem("urgentAlertAcknowledged") !== "ack"
      ) {
        return "ModalDialogUrgentMessage";
      }
      if (this.$route.name === "Logout" && this.user.logoutSurvey !== null) {
        return "SurveyPanel";
      }
      return "blank";
    },

    logoutCountdownDisplay() {
      const countdown = this.logoutCountdown;
      const seconds = countdown % 60;
      const minutes = Math.floor(countdown / 60);
      const pad = seconds < 10 ? "0" : "";
      let disp = minutes.toString() + ":" + pad + seconds.toString();
      return disp;
    },

  },

  // Called after $data created, but before template mounted
  created() {
    // get frontend version number from local file
    fetch('/version.txt')
      .then(response => {
        console.log("FE VERSION: ");
        //const stream = response.body.getReader;
        return response.text();
      })
      .then(data => this.feVersion = data)
      .catch((error) => {
        console.log("FE Version fetch error: " + error);
      });
    // get backend version number from API
    this.api.getVersion((resp) => {
      if (!resp.error) {
        this.beVersion = resp.version;
      }
    })
    // load general application config
    this.config = appConfig;
    // Combine with the client-specific config from the API
    this.getConfig(this.getLocale());

    // once we have the full config, pass config vars to UserPerms
    this.user = new UserPermissions({
      COORD_USER_TYPE: this.config.COORD_USER_TYPE,
      MENTOR_USER_TYPE: this.config.MENTOR_USER_TYPE,
      MENTEE_USER_TYPE: this.config.MENTEE_USER_TYPE,
    });

    // Set up the session worker if the user is authenticated
    // (for page refreshes after login)
    if (this.user.isAuthenticated()) {
      // For coordinators: start admin polling, and make sure we have the lookup values cached
      if (this.userIsCoordinator()) {
        this.startAdminPolling();
        if (this.lookupValues.size === 0) {
          this.getLookupValues("language");
          this.getLookupValues("gender");
          this.getLookupValues("ethnicity");
          this.getLookupValues("condition");
        }
      }
      this.createSessionWorker();
    }

    //For page refreshes: load admin-side counts from session storage, if they have been set
    this.unmatchedMentees = parseInt(sessionStorage.getItem("unmatchedMentees"));
    this.unreadMessageCount = parseInt(sessionStorage.getItem("unreadMessageCount"));
     this.urgentNoteCount = parseInt(sessionStorage.getItem("urgentNoteCount"));

  },

  methods: {
    handleMessage(e) {
      console.log("MESSAGE: " + e.data);
      if (e.data === "warning") {
        this.displaySessionTimeoutWarning();
      }
      if (e.data === "timeout") {
        this.logout();
      }
    },
    
    // lang param = ISO code
    getConfig(lang) {
      this.api.getConfig((config) => {
        this.config = { ...appConfig, ...config[0] };
        // generate data URLs for images from config.
        this.config.logo_src = this.createDataUrl(
          config[0].logo_mime,
          config[0].logo_image
        );
        this.config.login_src = this.createDataUrl(
          config[0].hero_mime,
          config[0].hero_image
        );

        // Add favicon
        const link = document.createElement("link");
        link.rel = "shortcut icon";
        link.type = config[0].favicon_mime;
        link.href = config[0].favicon;
        document.head.appendChild(link);

        // Set title to the default in the config. This should oly trigger on initial page loads
        document.title = this.$root.config.html_title_root;

        // persist config data to sessionStorage, so it can survive page reloads
        console.log("HEALTH LIBRARY");
        this.persistResourceKeys(config[0]);


        // Initialize logout countdown (in seconds)
        this.resetLogoutCountdown();
      }, lang);
    },

    persistResourceKeys(configObject) {
      const resourceKeys = ["health_library", "community_resources", "mentor_tips", "manage_health", "client_resources", "mentoring_skills", "mentee_skills"];
      resourceKeys.forEach((key => {
        let cat = key + "_category";
        let title = key + "_title";
        sessionStorage.setItem(cat, configObject[cat]);
        sessionStorage.setItem(title, configObject[title]);
      }));
      
    },

    removeResourceKeys() {
      const resourceKeys = ["health_library", "community_resources", "mentor_tips", "manage_health", "client_resources", "mentoring_skills", "mentee_skills"];
      resourceKeys.forEach((key => {
        let cat = key + "_category";
        let title = key + "_title";
        sessionStorage.removeItem(cat);
        sessionStorage.removeItem(title);
      }));
      
    },

    getResourceVar(variable) {
      // Get config loaded at login, if present. If not get from sessionStorage
      if (typeof(this.config[variable]) != 'undefined') {
        return this.config[variable];
      }
      const persisted = sessionStorage.getItem(variable);
      console.log(persisted);
      if (persisted !== null) {
        return persisted;
      }
      return undefined;
    },

    setLocale(locale) {
      // check that the locale code is valid for this client
      if (this.getLocales().indexOf(locale) === -1) {
        return;
      }
      this.getConfig(locale); // Call getConfig to load the appropriate language strings
      this.$i18n.locale = locale;
      localStorage.setItem("lang", locale);

    },

    getLocale() {
      return this.$i18n.locale;
    },

    getLocales() {
      return this.$i18n.availableLocales;
    },

    localize(key, ...args) {
      return this.$t(key, ...args);
    },

    // Note that this function does NOT accept merge fields, just a number argument
    localizePlural(key, number) {
      // i18n.$tc() gags on floating point numbers, so round down
      return this.$tc(key, Math.floor(number));
    },

    formatFullName(firstName, lastName) {
      // Non-Korean names are localized formatted in the Western way when locale=ko
      if (this.$i18n.locale === "ko" && !lastName.match(/[a-zA-Z]/)) {
        return lastName + firstName;
      }
      return firstName + " " + lastName;
    },

    getPasswordRequirements() {
      const locale = this.$i18n.locale;
      return this.$i18n.messages[locale].fieldLabels.passwordRequirements;
    },

    // Accepts and returns a reference to the session timeout ref variable in data().
    setSessionTimeOut() {
      console.log("SENDING RESET MESSAGE");
      this.sessionWorker.postMessage("reset");
    },

    displaySessionTimeoutWarning() {
      clearTimeout(this.sessionTimeoutRef); // Clear any existing timer
      // Set a new timeout to log out the user
      // this.sessionTimeoutRef = setTimeout(
      //   this.logout,
      //   this.config.SESSION_TIMEOUT_WARNING
      // );

      // Display the session timeout modal
      this.showSessionTimeoutWarning = true;

      // Set interval to refresh countdown on modal once per second
      clearInterval(this.sessionTimeoutCountdownRef); // Clear any existing timer
      this.resetLogoutCountdown();
      this.sessionTimeoutCountdownRef = setInterval(
        this.logoutCountdownTick,
        1000
      );
    },

    logoutCountdownTick() {
      this.logoutCountdown--;
    },

    resetSession() {
      console.log("Client session reset");
      this.setSessionTimeOut();
      this.showSessionTimeoutWarning = false;
    },

    handleContinueSessionClick() {
      clearInterval(this.sessionTimeoutCountdownRef);
      this.callAuthenticatedEndpoint("resetSession");
      this.resetSession();
    },

    resetLogoutCountdown() {
      this.logoutCountdown =
        parseInt(this.config.SESSION_TIMEOUT_WARNING) / 1000;
    },

    // Intercept the callback to determine if we need to log out or redirect user
    // callback may be a function of null if no callback is required
    callAuthenticatedEndpointNEW(endpoint, callback, ...rest) {
      this.api[endpoint](
        this.user.token,
        (response) => {
          console.log("NEW cae " + endpoint);
          console.log(response);
          // Intercept errors
          // TODO - deal with permission/PW/terms redirects
          if (
            typeof response?.error !== "undefined" &&
            response.error === true
          ) {
            console.log(
              "Error found in CAE new calling " +
                endpoint +
                ": " +
                response.error
            );
            // Log the user out if the error indicates the session has expired
            if (response?.invalidToken === true || 
              response?.detail === "Not found." ||
              response.error === "user not identified" || 
              response.error === "Authentication credentials were not provided.") {
              this.logout();
              return false;
            }
            
          } 
            // Most authenticated API calls reset the user session
            if (endpoint !== "resetSession") {
              this.resetSession();
            }
            if (typeof callback === "function") {
              callback(response);
            } else {
              return true;
            }
          
        },
        ...rest
      );
    },

    async callAuthenticatedEndpoint(endpoint, ...rest) {
      const response = await this.api[endpoint](this.user.token, ...rest);
      console.log("after await " + endpoint);
      console.log(response);
      //const returnValue = await this.processAuthenticatedEnpointCall(response);
      if (typeof response?.error !== "undefined" && response.error === true) {
        this.logout();
        return false;
      } else {
        // Most authenticated API calls reset the user session
        // TODO - add other methods that don't reset
        if (endpoint !== "resetSession") {
          this.resetSession();
        }
        console.log("cae 2 " + endpoint);
        console.log(response);
        return response;
      }
    },

    processAuthenticatedEnpointCall(response) {
      console.log("cae resp");
      console.log(response);
      // Force logout if the session has expired
      // TODO - redirect for expired PW, agree to terms, inadequate permissions
      if (typeof response?.error !== "undefined" && response.error === true) {
        this.logout();
        return false;
      } else {
        // Most authenticated API calls reset the user session
        // TODO - add other methods that don't reset
        // if (endpoint !== "resetSession") {
        //   this.resetSession();
        // }
        this.resetSession();
        console.log("cae 1");
        console.log(response);
        return response;
      }
    },

    createDataUrl(mime, base64) {
      let format = ";base64,";
      // Non-base64 encoded SVGs use a different syntax to other MIME types
      if (mime === "image/svg+xml") format = ",";
      return `data:${mime}${format}${base64}`;
    },

    getHomeRoute() {
      if (this.getUserType() === "unauthenticated") {
        return "login";
      }
      if (this.userIsCoordinator()) {
        return "AdminHomePeers";
      }
      return "Home";
    },

    createSessionWorker() {
      this.sessionWorker = new Worker("/js/ith.js");
      this.sessionWorker.onmessage = this.handleMessage;
    },

    // Sends user to the appropriate destination after login (home if no redirect in query string, else follow query string)
    handleLogin(email, password, callback) {
      //const _this = this;
      this.user.authenticate(email, password, async (response) => {
        console.log(response);
        // If the login is invalid
        if (response.error === true) {
          console.log("Authenticate error");
          // Extract the string error message ot pass back to caller.
          // Expired password error is an object; other login errors are arrays
          const callbackPayload = Array.isArray(response) ? response[0] : response.detail;
          callback(callbackPayload);
          return;
        }
        console.log("Passed authentication");
        if (response.show_before_chat_message === true) {
          this.showPeerMentorAdviceModal = true;
        }
        this.handlePostAuthentication(response.language_code);

        let route = { name: this.getHomeRoute() };
        if (this.$route.query.redirect !== undefined) {
          route = this.$route.query.redirect;
        }
        // For admin users, check if we need to show the urgent message modal after login
        // NB urgentMessageCount may be > 0 if the function is being called after login from Interstitial.vue
        console.log(route);
        if (this.urgentMessageCount === 0 && this.userIsCoordinator()) {
          // console.log("Is admin, urgent messages = 0");
          // this.checkUrgentMessages(route);

          // Load lookup values
          await this.getLookupValues("language");
          await this.getLookupValues("gender");
          await this.getLookupValues("ethnicity");
          await this.getLookupValues("condition");

          this.$router.push(route);
          return true;
        } else {
          console.log("REACHED LOGIN PUSH, route = " + route);
          this.$router.push(route);
          return true;
        }

      });
    },

    // Used in first login only. Redirect is handled within the FirstLogin component
    handleFirstLogin(apiResponse) {
      console.log("handleFirstLogin");
      this.user.setupUser(apiResponse);
      this.handlePostAuthentication(this.getLocale());
    },

    handlePostAuthentication(langCode) {
      this.createSessionWorker();

      // Set the locale to the user's preferred language
      this.setLocale(langCode);

       // Admin users - get unmatched mentor and unread message count for top nav display
      if (this.userIsCoordinator()) {
        this.startAdminPolling();
      }
      
    },

    updateAdminCounters() {
      this.$root.api.getAdminCounters(
        this.$root.user.token,
        resp => {
          this.unmatchedMentees = resp?.unmatched_mentees;
          sessionStorage.setItem("unmatchedMentees", resp?.unmatched_mentees);
          this.urgentNoteCount = resp?.urgent_session_notes;
          sessionStorage.setItem("urgentNoteCount", resp?.urgent_session_notes);
          this.unreadMessageCount = resp?.unread_messages;
          sessionStorage.setItem("unreadMessageCount", resp?.unread_messages);
        });
    },

    startAdminPolling() {
      this.updateAdminCounters();
      this.adminIntervalId = setInterval(
          this.updateAdminCounters,
          this.$root.config.ADMIN_POLL_INTERVAL
      );
      this.$root.chatIntervalId = this.intervalId;
    },

    decrementUnmatchedMenteeCount() {
      this.unmatchedMentees--;
      sessionStorage.setItem("unmatchedMentees", this.unmatchedMentees);
    },

    decrementUnreadMessageCount(count = 1) {
      console.log("decrement unread messages: " + count);
      const newCount = this.unreadMessageCount - count;
      if (newCount >= 0) {
        this.unreadMessageCount = newCount;
        sessionStorage.setItem("unreadMessageCount", newCount);
      }
    },

    incrementUnreadMessageCount(count = 1) {
      console.log("increment unread messages: " + count);
      const newCount = this.unreadMessageCount + count;
      this.unreadMessageCount = newCount;
      sessionStorage.setItem("unreadMessageCount", newCount);
    },

    decrementUnreadUrgentNotesCount(count=1) {
      console.log("decrement urgent notes: " + count);
      const newCount = this.urgentNoteCount - count;
      if (newCount >= 0) {
        this.urgentNoteCount = newCount;
        sessionStorage.setItem("urgentNoteCount", newCount);
      }
    },

    setUrgentMessageAcknowledged() {
      this.urgentAlertAcknowledged = true;
      sessionStorage.setItem("urgentAlertAcknowledged", "ack");
    },

    logout() {
      console.log("App.logout() called");

       // reset admin counts
      this.unmatchedMentees = 0;
      sessionStorage.removeItem("unmatchedMentees");
      sessionStorage.removeItem("urgentNoteCount");
      sessionStorage.removeItem("unreadMessageCount");
      sessionStorage.removeItem("urgentAlertAcknowledged");
      this.removeResourceKeys();

      this.sessionWorker.terminate();
      //reset urgent message acknowledgement
      this.urgentAlertAcknowledged = false;

      if (this.userIsCoordinator()) {
        clearInterval(this.adminIntervalId);
      }

      this.api.logout(this.user.token, (resp) => {
        console.log("resp from logout: " + JSON.stringify(resp.survey_token));
        // Hide the session timeout warning (if it is displayed)
        this.showSessionTimeoutWarning = false;
        // You can't pass null as a parameter to the router, only strings, so replace with "none"
        let surveyToken = "none";
        // Note that if the backend session times out first, then the logout endpoint call will
        // throw a 403 error, in which case resp.survey token won't exist
        if ("survey_token" in resp && resp.survey_token !== null) {
          surveyToken = resp.survey_token;
        }

        this.$router.push({
          name: "Logout",
          params: { survey: surveyToken },
        });
        
        this.user.logout();
      });
    },
    userIsAuthenticated() {
      if (this.getUserType() === "unauthenticated") {
        return false;
      }
      return true;
    },
    userIsMentee() {
      return this.user.userIsMentee();
    },
    userIsMentor() {
      return this.user.userIsMentor();
    },
    userIsCoordinator() {
      return this.user.userIsCoordinator();
    },
    getUserType() {
      if (this.user.userType === "notAuthenticated") {
        return "unauthenticated";
      }
      if (this.user.userIsMentee()) {
        return this.config.MENTEE_USER_TYPE;
      }
      if (this.user.userIsMentor()) {
        return this.config.MENTOR_USER_TYPE;
      }
      return this.config.COORD_USER_TYPE;
    },
    // Get the display string for a user's type. Accepts a user object.
    getDisplayUserType(user) {
      if (user.user_type === this.config.MENTEE_USER_TYPE || user.usertype === this.config.MENTEE_USER_TYPE) {
        return this.$root.config.mentee_label_singular;
      } else if (user.user_type === this.$root.config.MENTOR_USER_TYPE || user.usertype === this.config.MENTOR_USER_TYPE) {
        return this.$root.config.mentor_label_singular;
      }
      return this.$root.config.coord_label_singular;
    },

    // Call the API to return lookup table values
    getLookupValues(type) {
      type = type.toLowerCase();
      console.log("type: " + type);
      // Return the cached lookup values if any; otherwise get the values from the API
      if (this.lookupValues.has(type)) {
        return this.lookupValues.get(type);
      }
      let values = [];
      let method;
      switch (type) {
        case "status":
          method = "getStatuses";
          break;
        case "language":
          method = "getLanguages";
          break;
        case "gender":
          method = "getGenders";
          break;
        case "ethnicity":
          method = "getEthnicities";
          break;
        case "condition":
          method = "getConditions";
          break;
      }
      this.callAuthenticatedEndpointNEW(method, (items) => {
        for (let item of items) {
          // we need language and ID for the add and edit user calls
          if (method === "getLanguages") {
              values.push(item);
          // getConditions captures form definitions as well as the string titles
          } else if (method === "getConditions") {
            values.push({
              title: item.title,
              extra_fields_form: item.extra_fields_form
            });
          } else {
            // Each value has an id field (containing the string label) and a boolean is_active flag
          if (item.is_active) {
            values.push(item.title);

            // getConditions (optionally) captures form definitions as well as the string titles
            // if (method === "getConditions" && item?.extra_fields_form !== null) {
            //   const condFields = item.extra_fields_form;
            //   if (condFields !== null) {
            //     console.warn(condFields);
            //     const condition = item.title;
            //     this.conditionUserFields[condition] = this.addToConditionUserFields(condFields);
            //   }
            // }
          }
          }
          
        }
        this.lookupValues.set(type, values);
      });
      return values;
    },

    addToConditionUserFields(formDefinition) {
      let conditionSpec = {};
      for (let group in formDefinition.form_groups) {
        for (let field in formDefinition.form_groups[group].form_fields) {
          let id = formDefinition.form_groups[group].form_fields[field].id;
          conditionSpec[id] = "";
        }
      }
      return conditionSpec;
    },

    // Convert a date to a YYYY-MM-DD string. Uses client's local time zone.
    dateToYmd(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1).toString().padStart(2, 0) +
        "-" +
        date.getDate().toString().padStart(2, 0)
      );
    },
    // YYYY-MM-DD string to Date object
    ymdToDate(ymdString) {
      const nums = ymdString.split("-");
      const dt = new Date(nums[0], nums[1] - 1, nums[2]);
      return dt;
    },

    // Format an ISO 8601 string to a medium-length, localized date format
    formatDate(dateString) {
      const dt = this._parseDateString(dateString);
      const fmt = new Intl.DateTimeFormat("en-US", {
        dateStyle: this.config.DATETIMEFORMAT_DATE,
        timeZone: this.config.timezone,
      });
      return fmt.format(dt);
    },

    // Format an ISO 8601 string to a medium-length, localized date/time format
    formatDateTime(dateString) {
      const dt = this._parseDateString(dateString);
      const fmt = new Intl.DateTimeFormat("en-US", {
        dateStyle: this.config.DATETIMEFORMAT_DATE,
        timeZone: this.config.timezone,
        timeStyle: this.config.DATETIMEFORMAT_DATE,
      });
      return fmt.format(dt);
    },

    _parseDateString(dateString) {
      let dt;
      //force the correct timezone if none included
      if (dateString.length === 10) {
        dt = this.ymdToDate(dateString);
      } else {
        dt = new Date(dateString);
      }
      return dt;
    },

    // get day of week from an ISO 8601 date string
    // TODO - localize, move formatter to data()
    getDayOfWeek(dateString) {
      const dt = this.ymdToDate(dateString);
      console.log(dt);
      return new Intl.DateTimeFormat("en-US", {
        weekday: this.config.DATETIMEFORMAT_WEEKDAY,
        timeZone: this.config.timezone,
      }).format(dt);
    },

    // Leap-year aware function to get the latest allowable user birthday, based on
    // minimum user age in the config
    getLatestUserBirthday() {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth();
      let day = date.getDate();
      // If date is Feb 29, then the birthday on non-leap years is Mar 1
      if (month === 1 && day === 29) {
        month = 2;
        day = 1;
      }
      return new Date(year - this.config.USER_MIN_AGE, month, day);
    },

    formatNumber(number) {
      const locale = this.$i18n.locale;
      return new Intl.NumberFormat(locale).format(number);
    },

    //utility function for sorting primitive values
    sortItems(a, b, order='asc') {
      let sortA, sortB;
      if (order === 'asc') {
        sortA = a;
        sortB = b;
      } else {
        sortA = b;
        sortB = a;
      }

      // if sort keys are strings, return locale-aware case-insensitive search
      if (typeof sortA === "string" && typeof sortB === "string") {
        return sortA.localeCompare(sortB, this.user.locale, {
          sensitivity: "base",
        });
      }

      //otherwise, make a straight > / < comparison
      if (sortB > sortA) {
        return -1;
      }
      if (sortA > sortB) {
        return 1;
      }
      return 0;

    },

    //sort an array of objects ascending by a key field
    sortAsc(objects, sortKey) {
      return objects.sort((a, b) => {
        return this.sortItems(a[sortKey], b[sortKey]);
      });
    },
    //sort an array of objects descending by a key field
    sortDesc(objects, sortKey) {
      return objects.sort((a, b) => {
        return this.sortItems(a[sortKey], b[sortKey], 'desc');
      });
    },
  },
};

</script>

<style lang="scss" src="./scss/styles.scss"></style>
<!-- comment to trigger pipeline, disregard -->
