<template>
  <header v-bind:class="headerClass">
    <span class="logo">
				<img
          v-bind:src="$root.config.logo_image"
          v-bind:alt="$root.config.logo_alt"
        />
			</span>
    <h1 :class="titleClass">{{ $root.config.app_name }}</h1>
    <div v-if="$root.config?.tagline !== null && $route.name !== 'ResendInvitation'" class="dashboard--tagline">{{ $root.config.tagline }}</div>
    <div
      class="header--intro"
      v-if="showLoginText"
      v-html="$root.config.login_page_text"
    ></div>
    <nav v-if="showMenu === true" aria-label="Main">
      <input 
        v-model="menuOpen" 
        true-value="open"
        false-value="closed" 
        type="checkbox" 
        id="nav_toggle-0" 
        class="toggle toggle-nav"
        v-bind:class="{ checked: menuOpen === 'open'}"
        aria-hidden="true" />
      <label class="btn" for="nav_toggle-0" aria-hidden="true">{{
        $root.localize("global.menu.label")
      }}</label>
      <ul class="menu">
        <li>
          <router-link v-bind:to="{ name: 'AccountInfo' }">{{
            $root.localize("global.menu.accountInfo")
          }}</router-link>
        </li>
        <li v-if="$root.userIsMentee()">
          <router-link v-bind:to="{ name: 'BiographyMentee' }">{{
            $root.localize("global.menu.biography")
          }}</router-link>
        </li>
        <li v-if="$root.userIsMentor()">
          <router-link v-bind:to="{ name: 'Biography' }">{{
            $root.localize("global.menu.biography")
          }}</router-link>
        </li>
        <li>
          <input 
            v-model="subMenuOpen" 
            true-value="open"
            false-value="closed"   
            type="checkbox" 
            id="nav_toggle-1" 
            class="toggle" 
            v-bind:class="{ checked: subMenuOpen === 'open'}"
            aria-hidden="true">
          <label for="nav_toggle-1">{{ $root.localize("global.menu.agreements") }}</label>
          <ul class="menu">
            <li>
              <router-link v-bind:to="{ name: 'ViewAgreement' }">
                {{ $root.localize(agreementKey) }}
              </router-link>
            </li>
            <li>
              <router-link v-bind:to="{ name: 'ViewPrivacy' }">
                {{ $root.localize("screens.Interstitial.privacy") }}
              </router-link>
            </li>
            <li>
              <router-link v-bind:to="{ name: 'ViewTerms' }">
                {{ $root.localize("screens.Interstitial.license") }}
              </router-link>
            </li>
          </ul>
        </li>
        <li>
          <a href="#" v-on:click="this.$root.logout">{{
            $root.localize("global.menu.signOut")
          }}</a>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "PeerMentorHeader",

  props: {
    showMenu: {
      type: Boolean,
      default: true,
    },
    showLoginText: {
      type: Boolean,
      default: false,
    },
    headerClass: {
      type: String,
      default: ""
    },
    titleClass: {
      type: String,
      default: "header--title"
    }
  },

  data() {
    return {
      agreement: "",  // Agreement to join or volunteer
      agreementKey: "screens.Interstitial.joinAgreement",  // Translation file key for the agreement display
      menuOpen: "closed",
      subMenuOpen: "closed",
    }
  },

  created() {
    // Select endpoint for agreement menu text, if menu displayed
    if (this.$root.userIsAuthenticated() && this.showMenu) {
      let endpoint = "getAgreementJoin";
      if (this.$root.userIsMentor()) {
          endpoint = "getAgreementVolunteer";
          this.agreementKey = "screens.Interstitial.volunteerAgreement";
      }
      this.$root.callAuthenticatedEndpointNEW(endpoint, (resp) => {
      this.agreement = resp.text;
      }, this.$root.getLocale());
    }
  },

  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>
