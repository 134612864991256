<template>
  <img
    class="main--hero"
    v-bind:src="$root.config.hero_image"
    width="800"
    height="398"
    v-bind:alt="$root.config.hero_alt"
  />
  <div class="language" v-if="supportedLanguages.length > 1">
    <div class="language--title">
      {{ $root.localize("fieldLabels.languagePref") }}
    </div>
    <ul class="language--list">
      <li
        v-for="locale in supportedLanguages"
        v-bind:key="locale"
        class="language--item"
      >
        <a
          v-if="$i18n.locale !== locale"
          v-on:click="$root.setLocale(locale)"
          href="#"
          >{{ $i18n.messages[locale].languageName }}</a
        >
        <span v-else>{{ $i18n.messages[locale].languageName }}</span>
      </li>
    </ul>
  </div>
  <h2 class="main--heading">{{ $root.localize("screens.Login.greeting") }}</h2>
  <form class="login--form" v-on:keyup.enter="submit">
    <div v-if="errorMessage" class="notice notice-error">
      {{ errorMessage }}
    </div>
    <div class="field field-email">
      <label for="login_email" class="field--label">{{
        $root.localize("fieldLabels.emailAddress")
      }}</label>
      <input
        ref="emailField"
        v-model.trim="email"
        type="email"
        id="login_email"
        name="login_email"
        class="field--input field--input-email"
        required="required"
      />
    </div>
    <div class="field field-password">
      <label for="login_password" class="field--label">{{
        $root.localize("fieldLabels.password")
      }}</label>
      <input
        ref="passwordField"
        v-model="password"
        v-bind:type="fieldType"
        id="login_password"
        name="login_password"
        class="field--input field--input-password"
        required="required"
      />
      <a
        v-on:click.prevent="togglePasswordVisibility"
        class="field--showhide"
        v-bind:aria-label="$root.localize(toggleLinkAriaLabel)"
        >{{ $root.localize(toggleLinkText) }}</a
      >
      
    </div>
    <div class="field field-auxiliary">
      <router-link v-bind:to="{ name: 'ForgotPassword' }" class="field--forgotpw">{{
        $root.localize("screens.Login.forgotPassword")
      }}</router-link>
      <span class="field--signup">{{ $root.localize("screens.Login.noAccount") }} <router-link v-bind:to="{ name: 'ResendInvitation' }">{{
        $root.localize("screens.Login.signUp")
      }}</router-link></span>
    </div>
    <section class="overlay" v-bind:class="{ 'overlay-open': showPopup }">
      <div class="overlay--inner">
        <header class="overlay--header">
          <h2 class="overlay--title">
            {{ $root.localize(`screens.Interstitial.${popupDocument}`) }}
          </h2>
          <a class="close overlay--close" href="#" v-on:click="closePopup">{{ $root.localize("global.buttons.close") }}</a>
        </header>
        <div class="overlay--content" v-html="popUpContent">
        </div>
      </div>
    </section>
    <footer class="form--footer">
      <IthButton
      type="primary"  
      v-bind:isDisabled="submitDisabled"
      v-on:buttonClick="submit"
      ref="submitButton"
      >
        {{ $root.localize("screens.Login.btnLogIn") }}
      </IthButton>
      <ul class="footer--links">
        <li><a href="#" v-on:click="openPopup('privacy')">{{ $root.localize("screens.Interstitial.privacy") }}</a></li>
        <li><a href="#" v-on:click="openPopup('license')">{{ $root.localize("screens.Interstitial.license") }}</a></li>
      </ul>
    </footer>
  </form>
</template>

<script>
import BodyClass from "../mixins/BodyClass";
import PageTitle from "../mixins/PageTitle";
import IthButton from "../components/IthButton.vue";
export default {
  name: "Login",

  components: { IthButton },

  mixins: [BodyClass, PageTitle],

  props: {
    // The page the user tried to navigate to (if any)
    target: {
      type: String,
      default: "Home",
    },
  },

  data() {
    /* Defaults to password hidden state */
    return {
      bodyClass: "login",

      // Models for inputs
      email: "",
      password: "",

      //Container for login error messages
      errorMessage: "",

      // Password field visibility - defaults to password hidden
      fieldType: "password",
      toggleLinkText: "fieldLabels.fieldShow",
      toggleLinkAriaLabel: "fieldLabels.passwordShowAria",

      // Will be overidden by URL variable
      supportedLanguages: ["en-US"],

      // Legalese popup display toggle and contents
      showPopup: false,
      popupDocument: "",
      popUpContent: "",
    };
  },

  computed: {
    // Valid state for the entire form
    isValid() {
      // JS version of https://emailregex.com/
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegex.test(this.email) && this.password !== "";
    },

    submitDisabled() {
      return !this.isValid;
    },
  },

  created() {
    // Generate supported language list from the URL
    const isoListRegex =
      /^[a-z]{2}(-[A-Z]{2}){0,1}(,[a-z]{2}(-[A-Z]{2}){0,1})*$/;
    const params = new URLSearchParams(window.location.search);
    const langs = params.get("supportedLangs");
    // proceed only if passed values is a comma-delimited list of ISO codes with no spaces
    if (isoListRegex.test(langs)) {
      // TODO - test if langs match config
      this.supportedLanguages = langs.split(",");
    }
  },

  mounted() {
    console.log(this.$route.query);
  },

  methods: {
    togglePasswordVisibility() {
      this.fieldType = this.fieldType === "password" ? "text" : "password";
      this.toggleLinkText =
        this.toggleLinkText === "fieldLabels.fieldShow"
          ? "fieldLabels.fieldHide"
          : "fieldLabels.fieldShow";
      this.toggleLinkAriaLabel =
        this.toggleLinkAriaLabel === "fieldLabels.passwordShowAria"
          ? "fieldLabels.passwordHideAria"
          : "fieldLabels.passwordShowAria";
    },

    openPopup(document) {
      if (document !== this.popupDocument) {
        this.popupDocument = document;
        if (document === "privacy") {
          this.$root.api.getPrivacy("", (resp) => {
            this.popUpContent = resp.text;
            this.showPopup = true;
          }, this.$root.getLocale());
        } else {
          this.$root.api.getTerms("", (resp) => {
            this.popUpContent = resp.text;
            this.showPopup = true;
          }, this.$root.getLocale());
        }
      } else {
        this.showPopup = true;
      }
    },

    closePopup() {
      this.showPopup = false;
    },

    submit() {
      console.log(`Submission isValid: ${this.isValid}`);
      // the form shouldn't ever be invalid if it can be submitted, but just in case
      if (!this.isValid) {
        this.$refs.submitButton.stopLoading();
        return;
      }

      // $root.handleLogin() will redirect if the login is valid, or return an error message if it isn't
      this.$root.handleLogin(this.email, this.password, (resp) => {
        console.log(resp);
        if (resp.match(this.$root.config.ACCOUNT_LOCKED_MESSAGE)) {
            this.errorMessage = this.$root.localize(
              "screens.Login.errorLockedAccount"
            );
        } else if (resp.match(this.$root.config.PASSWORD_EXPIRED_MESSAGE)) {
          this.errorMessage = this.$root.localize("screens.Login.errorPasswordExpired");
        } else {
          this.errorMessage = this.$root.localize(
              "screens.Login.errorBadCreds"
            );
        }
        this.$refs.submitButton.stopLoading();
      });
    },
  },
};
</script>
