export default {
  props: {
    pageTitle: {
      type: String,
      required: false,
    },
  },

  //set the appropriate body class
  created() {
    let title = this.$root.config.html_title_root;
    // Page title will be an i18n key, passed by the router
    if (this.pageTitle) {
      const titleText = this.$root.localize(this.pageTitle);
      title = `${titleText} - ${title}`;
    }
    document.title = title;
  },
};
