<template>
  <section class="modal">
    <div class="modal--inner" v-bind:class="wideClass">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  name: "BaseModalDialog",

  props: {
    wide: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    wideClass() {
      if (!this.wide) {
        return "";
      }
      return "modal--wide"
    }
  },
};
</script>
