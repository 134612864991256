<template>
  <header class="notification--header">
    <span class="logo">
      <img
        v-bind:src="$root.config.logo_image"
        v-bind:alt="$root.config.logo_alt"
      />
    </span>
    <h1 class="header--title">
      {{ $root.localize("screens.Logout.pageHeader") }}
    </h1>
  </header>
</template>

<script>
export default {};
</script>
